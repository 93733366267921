import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Label from "./label";

const Root = styled.div`
  margin: 40px auto;

  input,
  textarea {
    display: block;
    width: 100%;
    border-radius: 45px;
    padding: 24px 36px;
    margin-top: 20px;

    border: none;
    background-color: ${p => p.theme.white};
    color: ${p => p.theme.blueDark};
    font-family: ${p => p.theme.baseFont};
    font-size: ${p => p.theme.rem18};
    transition: ${p => p.theme.transitionDefault};
    line-height: 28px;

    /* Placeholders */
    ::placeholder {
      color: ${p => p.theme.greyLight};
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${p => p.theme.greyLight};
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${p => p.theme.greyLight};
    }

    &:focus {
      outline: none;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    ${p => p.touched
      && p.error
      && `
      color: ${p.theme.red};
    `}

    @media (min-width: ${p => p.theme.screenL}) {
      padding: 30px 42px;
      font-size: ${p => p.theme.rem20};
    }
  }

  textarea {
    min-height: ${p => (p.minHeight ? p.minHeight : `400px`)};
  }
`;

const InputTemplate = ({
  name,
  label,
  color,
  value,
  minHeight,
  error,
  touched,
  children,
}) => {
  return (
    <Root value={value} error={error} touched={touched} minHeight={minHeight}>
      <Label htmlFor={name} error={error} touched={touched} color={color}>
        {error && touched ? error : label}
      </Label>

      {children}
    </Root>
  );
};

InputTemplate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  minHeight: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default InputTemplate;
