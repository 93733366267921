import React from "react";
import PropTypes from "prop-types";
import { Form as FormikForm } from "formik";

// Default Formik <Form> element doesn't include noValidate prop
// to prevent the default browser validation so use this instead
const Form = ({ children }) => {
  return <FormikForm noValidate>{children}</FormikForm>;
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Form;
