import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-autosize-textarea";

import InputTemplate from "./inputTemplate";

const Input = ({
  placeholder,
  name,
  value,
  minHeight,
  error,
  touched,
  children,
  ...rest
}) => {
  return (
    <InputTemplate
      name={name}
      value={value}
      error={error}
      touched={touched}
      minHeight={minHeight}
      {...rest}
    >
      <TextareaAutosize
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
    </InputTemplate>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  minHeight: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Input;
