import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

import { Absolute } from "../containers/position";
import { TTShield } from "../svg/icons";
import { TopTekkersLogoSmall } from "../svg/logos";

const Root = styled.div`
  ${p => p.hideOnMobile
    && `
  display: none;

  @media (min-width: ${p.theme.screenM}) {
    display: block;
  }
`}
`;

const ShieldIcon = styled(Absolute)`
  svg:last-of-type {
    position: absolute;

    ${p => p.large
      && `
    top: 20px;
    left: 24px;
    `}

    ${p => p.small
      && `
    top: 12px;
    left: 13px;
    `}
  }
`;

const Shield = ({
  top,
  bottom,
  left,
  right,
  large,
  fill,
  hideOnMobile,
  theme,
}) => {
  return (
    <Root hideOnMobile={hideOnMobile}>
      {!large ? (
        <ShieldIcon top={top} bottom={bottom} left={left} right={right} small>
          <TTShield size="m" fill={fill} />
          <TopTekkersLogoSmall width="20px" fill={theme.yellow} />
        </ShieldIcon>
      ) : (
        <ShieldIcon top={top} bottom={bottom} left={left} right={right} large>
          <TTShield size="xl" fill={fill} />
          <TopTekkersLogoSmall width="40px" fill={theme.yellow} />
        </ShieldIcon>
      )}
    </Root>
  );
};

Shield.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  large: PropTypes.bool,
  fill: PropTypes.string.isRequired,
  hideOnMobile: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Shield);
