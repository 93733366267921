import React from "react";
import PropTypes from "prop-types";

import SliderTemplate from "./sliderTemplate";

const Slider = ({
  name,
  label,
  value,
  step,
  required,
  error,
  touched,
  onChange,
  onBlur,
  rangeMin,
  rangeMax,
  children,
  ...rest
}) => {
  return (
    <SliderTemplate
      name={name}
      label={label}
      value={value}
      rangeMin={rangeMin}
      rangeMax={rangeMax}
      required={required}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      touched={touched}
    >
      {children}

      <input
        name={name}
        value={value}
        type="range"
        min={rangeMin}
        max={rangeMax}
        id={name}
        onChange={onChange}
        step={step}
        required={required}
        {...rest}
      />
    </SliderTemplate>
  );
};

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  step: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rangeMax: PropTypes.string,
  rangeMin: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Slider;
