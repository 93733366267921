import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

import Banner from "../containers/banner"
import ShieldIcon from "../globals/shield"
import MaxWidthAbsolute from "../containers/maxWidthAbsolute"

export const CalculatorBanner = ({ children, theme, ...rest }) => {
  return (
    <Banner bg={theme.yellow} {...rest}>
      {children}

      {/* TT shield icons */}
      <MaxWidthAbsolute>
        <ShieldIcon top="5%" left="12%" fill={theme.purple} />
        <ShieldIcon top="40%" left="2%" fill={theme.red} large hideOnMobile />
        <ShieldIcon bottom="10%" left="6%" fill={theme.green} />
        <ShieldIcon
          bottom="30%"
          left="30%"
          fill={theme.orange}
          large
          hideOnMobile
        />

        <ShieldIcon
          top={[`6%`, null, `21%`]}
          right={[`-4%`, null, `8%`]}
          fill={theme.blue}
          large
        />
        <ShieldIcon
          bottom={[`30%`, null, `36%`]}
          right="20%"
          fill={theme.pink}
          hideOnMobile
        />
        <ShieldIcon bottom="8%" right="5%" fill={theme.orange} />
      </MaxWidthAbsolute>
    </Banner>
  )
}

CalculatorBanner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(CalculatorBanner)
